<!--  -->
<template>
  <div class="tabbar">
    <van-tabbar v-model="active" active-color="#0000ff" inactive-color="#000" route>
      <van-tabbar-item to="/index">
        <i slot="icon" class="icon icon-chuangjiangongdan"></i>
        <span class="text">首页</span>
      </van-tabbar-item>
      <van-tabbar-item to="/task">
        <i slot="icon" class="icon icon-renwujincheng-copy"></i>
        <span class="text">任务</span>
      </van-tabbar-item>
      <van-tabbar-item>
        <van-popover
          v-model="showPopover"
          placement="top"
          class="look"
          @select="select"
          @close="select"
          @click-overlay="select"
        >
          <van-grid
            square
            clickable
            :border="false"
            column-num="3"
            style="width: 320px"
            icon-size="60px"
          >
            <van-grid-item
              v-for="i in lookList"
              :key="i.id"
              replace
              :text="i.value"
              :icon="i.icon"
              @click="GGB(i.url)"
            />
              <!-- :to="i.url" -->
          </van-grid>
          <template #reference>
            <div :class="icon" @click="lookAll"></div>
          </template>
        </van-popover>
      </van-tabbar-item>
      <van-tabbar-item to="/board">
        <i slot="icon" class="icon icon-AIkanbanjigou-copy"></i>
        <span class="text">看板</span>
      </van-tabbar-item>
      <van-tabbar-item to="/user">
        <i slot="icon" class="icon icon-personal"></i>
        <span class="text">个人</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      active: 0,
      icon: "icon icon-jiahao",
      showPopover: false,
      lookList: [
        {
          value: "新建工单",
          id: 1,
          icon: require("@/common/img/xinj.png"),
          url: "/createWork",
        },
        {
          value: "生产任务",
          id: 2,
          icon: require("@/common/img/shengc.png"),
          url: "/production",
        },
        {
          value: "新建报工",
          id: 3,
          icon: require("@/common/img/baogong.png"),
          url: "/reportWork1",
        },
        {
          value: "今日产量",
          id: 4,
          icon: require("@/common/img/chanl.png"),
          url: "/output",
        },
        {
          value: "今日报工",
          id: 5,
          icon: require("@/common/img/jinri.png"),
          url: "/job",
        },
        {
          value: "今日不良",
          id: 6,
          icon: require("@/common/img/bul.png"),
          url: "/unqualified",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onSelect(action) {
      Toast(action.text);
    },
    lookAll() {
      if (this.icon == "icon icon-jiahao") {
        this.icon = "icon icon-cha1";
        this.showPopover = true;
      } else {
        this.icon = "icon icon-jiahao";
        this.showPopover = false;
      }
    },
    select() {
      this.showPopover = false;
      this.icon = "icon icon-jiahao";
    },
    GGB(url){
      window.location.href= url;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.tabbar{
    /deep/ .icon{
      font-size: 36px;
    }
    /deep/ .icon-jiahao{
        color: blue;
        font-size: 50px;
    }
    /deep/ .icon-cha1{
        color: red;
        font-size: 55px;
    }
}
</style>